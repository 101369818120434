import React, {useState, useRef, useEffect} from "react";
import {useForm} from "react-hook-form";
import Link from "next/link";
import {useRouter} from "next/router";

import {useUser} from "../../context/userContext";
import firebase from "@/firebase";

const LoginForm = () => {
  const {setUserData} = useUser();
  const [loading, setLoading] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [firebaseError, setFirebaseError] = useState(null);
  const {register, handleSubmit, watch, setError, errors} = useForm();
  const [passwordResetSent, setPasswordResetSent] = useState(false);
  const firstInputRef = useRef();
  const router = useRouter();

  const loginWithGoogle = async () => {
    // OAuth providers authenticate in an asynchronous manner, so you’ll want to perform the link account link in the callback.
    const previousUser = firebase.auth().currentUser;

    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/userinfo.email");

    const signInPromise = firebase.auth().signInWithRedirect(provider);
    if (previousUser && previousUser.isAnonymous) {
      signInPromise.then(function ({credential}) {
        try {
          previousUser.linkWithCredential(credential).catch(err => {
            // ignore errors
          });
        } catch (err) {
          // ignore non-promise errors
        }
      });
    }
  };

  const email = watch("email");

  useEffect(() => {
    // Focus the email input on render
    firstInputRef.current.focus();
  }, [firstInputRef]);

  const handleSuccessfulAuth = user => {
    setUserData(user);
    // Redirecting to the homepage takes a long time (because the homepage is big)
    // So set a temporary UI state for the user
    setIsRedirecting(true);
    router.back();
  };

  const handleResetPassword = () => {
    if (!email) {
      setError([{name: "email", message: "Please enter your email address"}]);
      return;
    }

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        setPasswordResetSent(true);
      })
      .catch(error => {
        console.log("Error sending password reset", error);
      });
  };

  const handleSignInEmailUser = ({email, password}, event) => {
    setLoading(true);
    const previousUser = firebase.auth().currentUser;

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(result => {
        if (previousUser && previousUser.isAnonymous) {
          try {
            previousUser.linkWithCredential(result.credential).catch(err => {
              // ignore errors, failed links mean the account's already linked
            });
          } catch (err) {
            // ignore non-promise errors
          }
        }

        handleSuccessfulAuth(result.user);
        setLoading(false);
      })
      .catch(error => {
        setFirebaseError(error.message);
        event.target.reset();
        setLoading(false);
      });
  };

  if (passwordResetSent) {
    return <h3>To reset your password, click the link in the email we just sent you.</h3>;
  }

  if (isRedirecting) {
    return (
      <Loading>
        <Alert color="info">Redirecting...</Alert>
      </Loading>
    );
  }

  return (
    <div>
      <h2>Log in</h2>
      <form onSubmit={handleSubmit(handleSignInEmailUser)}>
        <fieldset>
          <div className="mt-2 shadow-sm">
            <input
              tabIndex="0"
              id="email"
              name="email"
              type="email"
              placeholder="Email"
              ref={e => {
                register(e, {required: true});
                firstInputRef.current = e;
              }}
            />
          </div>
          {errors.email && <div>An email address is required.</div>}
        </fieldset>
        <fieldset>
          <div>
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              ref={register({required: true})}
            />
          </div>
        </fieldset>
        <footer>
          <button small type="button" onClick={handleResetPassword}>
            Forgot password
          </button>
          <button type="submit" disabled={loading} loading={loading.toString()} className={loading && "loading"}>
            {loading && <i className="far fa-spinner-third fa-spin" />} <span>Log in</span>
          </button>
        </footer>
        <div className="button-row--google">
          <button type="button" onClick={loginWithGoogle}>
            <i className="fab fa-google" /> <span>Sign in with Google</span>
          </button>
        </div>
        <Link href="/signup">
          <button small="true" type="button">
            {`I don't have an account yet`}
          </button>
        </Link>
        {firebaseError && <Alert>{firebaseError}</Alert>}
      </form>
    </div>
  );
};

export default LoginForm;
