import LoginForm from "../components/Auth/LoginForm";

const Login = () => {
  return (
    <div>
      <LoginForm />
    </div>
  );
};

export default Login;
